import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import Seo from "../components/seo"

import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import WebriQForm from "@webriq/gatsby-webriq-form"
// import ContactUsBg from "../images/new-images/contact-us-bg.jpg"

import { LazyLoadComponent } from "react-lazy-load-image-component"
import "./contact.css"

import Testimonials2 from "../components/swiper/testimonials-new"

const Banner = () => {
  //   const { heroImage } = useStaticQuery(
  //     graphql`
  //       {
  //         heroImage: file(relativePath: { eq: "new-images/contact-us-bg.jpg" }) {
  //           childImageSharp {
  //             gatsbyImageData(
  //               width: 1920
  //               layout: CONSTRAINED
  //               formats: [AUTO, WEBP, AVIF]
  //             )
  //           }
  //         }
  //       }
  //     `
  //   )

  //   const heroImageData = getImage(heroImage)

  //   // Use like this:
  //   const bgImage = convertToBgImage(heroImageData)

  return (
    // <div
    //   className="hero-banner contact-banner"
    //   style={{
    //     background: `url(${ContactUsBg}) bottom no-repeat`,
    //     backgroundBlendMode: "overlay",
    //   }}
    // >
    <div
      // Tag="div"
      // {...bgImage}
      // fluid={imageData}
      className="contact-banner hero-banner"
      // preserveStackingContext
      style={{
        backgroundPosition: "bottom",
        // backgroundBlendMode: "overlay",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-9 mx-auto text-center">
            {/* <div className="hero-custom-content text-center"> */}
            <div className="hero-custom-quickbase text-center">
              <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                CONTACT
              </p>
              <h1 className="page-title font-weight-medium text-black mt-2">
                We're Ready to Transform Your Business
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class ContactPage extends React.Component {
  render() {
    // const { data } = this.props
    // const teams = data.allSanityTeam.

    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Contact Us | Quandary Consulting Group"
          description="General questions? Need support on a Quickbase or Workato Project? Looking to scale your business systems? Contact us today!"
        />

        <section id="speak-with-specialist" className="pb-5">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 m-auto form-wrap-contact"
                style={{ paddingTop: "80px" }}
              >
                <div className="intro mb-5">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="mt-0 font-weight-medium">
                        Speak with a Technology Consultant
                      </h2>
                      <p>
                        How can we help you? Use the form below to drop us an
                        e-mail. We'll be in touch shortly.
                      </p>
                      <small className="text-red">
                        Note: Form fields with * are required and must be filled
                        up in order to submit.
                      </small>
                    </div>
                  </div>
                </div>
                <WebriQForm
                  id="contact-form"
                  name="Contact Form"
                  className="contactForm"
                  data-form-id="4d46fe65-4d66-46b4-946c-e5826349ed3c"
                  data-thankyou-url="/thank-you"
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      {/* <p class="required">Full Name</p> */}

                      <input
                        type="text"
                        name="Full Name"
                        size={40}
                        className="form-control required"
                        placeholder="Full Name *"
                        aria-label="Full Name"
                        required
                        id="requiredField"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      {/* <p class="required">Phone</p> */}
                      <input
                        type="text"
                        name="Phone"
                        size={40}
                        // minLength={14}
                        className="form-control required"
                        id="phone"
                        placeholder="Phone Number *"
                        aria-label="Phone"
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      {/* <p class="required">Email</p> */}
                      <input
                        type="email"
                        name="Email"
                        size={40}
                        className="form-control"
                        aria-label="Email"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      {/* <p class="required">Message</p> */}
                      <textarea
                        name="Message"
                        rows={10}
                        cols={0}
                        className="form-control"
                        aria-label="Message"
                        placeholder="Message *"
                        required
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                      {/* <p class="required">How did you find out about us?</p> */}
                      <div>
                        <textarea
                          type="text"
                          name="How did you find out about us?"
                          size={40}
                          rows={5}
                          className="form-control"
                          aria-label="How did you find out about us?"
                          placeholder="How did you find out about us? *"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="form-group col-12 d-flex justify-content-center captcha-wrap">
                      <div className="webriq-recaptcha" />
                    </div>
                    <div className="form-group col-12 text-center">
                      <div className="input-filled">
                        <button
                          type="submit"
                          className="btn btn-primary btn-arrow"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </WebriQForm>
              </div>
            </div>
          </div>
        </section>
        <section id="contact-wrapper" className="component pt-0">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6"
                style={{ borderRight: "2px solid #cacaca" }}
              >
                <div className="intro mb-4">
                  <p className="h5 text-light-blue text-uppercase font-weight-bold mt-0 mb-3">
                    Where You Can Find Us
                  </p>
                </div>
                <ul className="list-unstyled contact-info">
                  <li className="mb-3">
                    <i
                      className="fa fa-map-marker fa-lg mr-4"
                      aria-hidden="true"
                    />
                    <span>
                      6400 S Fiddlers Green Circle - Suite 250 <br />
                      Greenwood Village, CO 80111
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-mobile fa-2x mr-4" aria-hidden="true" />
                    <a
                      aria-label="link"
                      href="tel:+17207391406"
                      onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '720-739-1406', 0);"
                    >
                      (720) 739-1406
                    </a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-envelope mr-4" aria-hidden="true" />
                    <a aria-label="link" href="mailto:info@quandarycg.com">
                      info@quandarycg.com
                    </a>
                  </li>
                  <li className="mb-3">
                    <i
                      className="fa fa-linkedin mr-4"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <a
                      href="https://www.linkedin.com/company/quandarycg"
                      ref="noreferrer noopener"
                    >
                      quandarycg
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="contact-map">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3074.2109991522548!2d-104.8919906!3d39.5999291!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c878152b57ac3%3A0x19ed7cd94c8a6747!2s6400%20S%20Fiddlers%20Green%20Cir%2C%20Greenwood%20Village%2C%20CO%2080111%2C%20USA!5e0!3m2!1sen!2sph!4v1696398667775!5m2!1sen!2sph"
                    // src="https://maps.google.com/maps?q=4980%20Harlan%20Street%20Denver,%20Colorado%2080212&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    height={435}
                    frameBorder={0}
                    style={{ border: "0px", pointerEvents: "none" }}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <LazyLoadComponent>
          <div className="text-center intro bg-gradient">
            <Testimonials2 />
          </div>
        </LazyLoadComponent>
      </Layout>
    )
  }
}

export default ContactPage
